var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('navegtion-component',{attrs:{"title":"REGISTRA TU CUENTA","sub_title":"REGISTRA TU CUENTA"}})],1)],1),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-row',{staticClass:"mt-10",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"my-10",attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"py-5 px-5",staticStyle:{"box-shadow":"0 10px 15px -3px rgba(0, 0, 0, 0.1) !important","border-radius":"20px !important"},attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-avatar',{attrs:{"tile":"","size":"200"}},[_c('v-img',{attrs:{"src":require("@/assets/img/logo.svg"),"contain":""}})],1),_c('v-divider',{staticClass:"my-5"})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"nombre"}},[_vm._v("Nombre")]),_c('ValidationProvider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"black","dense":"","outlined":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.first_name),callback:function ($$v) {_vm.first_name=$$v},expression:"first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('label',{attrs:{"for":"Codigo Postal"}},[_vm._v("Codigo Postal")]),_c('ValidationProvider',{attrs:{"name":"codigo postal","rules":"required|min:4|max:4|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"black","dense":"","outlined":"","placeholder":"Ingresa tu codigo postal","error-messages":errors},model:{value:(_vm.postal_code),callback:function ($$v) {_vm.postal_code=$$v},expression:"postal_code"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"email|required|confirmed:email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"}},[_vm._v(" Correo Electrónico ")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"autocomplete":"off","color":"black","dense":"","outlined":"","placeholder":"Ingresa tu correo electronico","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar correo","rules":"email|required","vid":"email_confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email confirmar"}},[_vm._v(" Confirmar Correo ")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"autocomplete":"off","color":"black","dense":"","outlined":"","placeholder":"Confrima tu correo electronico","error-messages":errors},model:{value:(_vm.email_confirmation),callback:function ($$v) {_vm.email_confirmation=$$v},expression:"email_confirmation"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"contraseña","rules":"required|confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v(" Contraseña ")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","outlined":"","placeholder":"Ingresa tu contraseña","error-messages":errors},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"confirmar contraseña","rules":"required","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password confirmar"}},[_vm._v(" Confirmar Contraseña ")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"autocomplete":"off","type":"password","color":"black","dense":"","outlined":"","placeholder":"Confirma tu nombre contraseña","error-messages":errors},model:{value:(_vm.confirm_password),callback:function ($$v) {_vm.confirm_password=$$v},expression:"confirm_password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-md-flex justify-md-space-between"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"loading":_vm.loading,"tile":"","elevation":"0","large":"","dark":"","color":"black"},on:{"click":function($event){return passes(_vm.HandlerSignUp)}}},[_vm._v(" Continuar ")])],1),_c('span',{staticClass:"d-flex justify-center mt-2 blue--text",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.HandlerRouter('login')}}},[_vm._v("Ya tienes cuenta? Inicia sesion ")])])])],1)],1)],1)],1)]}}])}),_c('v-dialog',{attrs:{"max-width":"600","persistent":""},model:{value:(_vm.showNotification),callback:function ($$v) {_vm.showNotification=$$v},expression:"showNotification"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Confirma tu cuenta para continuar ")]),_c('v-card-text',[_c('p',{staticClass:"mb-0 mt-10 black--text"},[_vm._v(" Se ha enviado un correo para confirmar su cuenta, porfavor copie el codigo en el mismo e inicie sesion para validar. ")])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"dark":"","color":"black","tile":""},on:{"click":function($event){return _vm.$router.push({ name: 'login' })}}},[_vm._v(" Continuar ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }