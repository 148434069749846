var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-card',{staticClass:"elevation-0",attrs:{"color":"white","tile":""}},[(_vm.getAuthUser.buyer != null)?_c('v-card-text',[_c('v-row',{staticClass:"mx-5 my-5",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"nombre"}},[_vm._v("NOMBRE")]),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"dense":"","color":"#00A0E9","filled":"","flat":"","placeholder":"Ingresa tu nombre","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.first_name),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "first_name", $$v)},expression:"getAuthUser.buyer.first_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"apellido"}},[_vm._v("APELLIDO")]),_c('ValidationProvider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2 elevation-0",attrs:{"color":"#00A0E9","filled":"","dense":"","flat":"","placeholder":"Ingresa tu apellido","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.last_name),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "last_name", $$v)},expression:"getAuthUser.buyer.last_name"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"tipo documento"}},[_vm._v(" Tipo Documento ")]),_c('ValidationProvider',{attrs:{"name":"Tipo de documento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-2",attrs:{"items":['DNI', 'CUIT'],"color":"#00A0E9","filled":"","dense":"","flat":"","placeholder":"Selecciona tu tipo de documento","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.doc_type),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "doc_type", $$v)},expression:"getAuthUser.buyer.doc_type"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"documento"}},[_vm._v(" Numero de Documento ")]),_c('ValidationProvider',{attrs:{"name":"Numero de documento","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"#00A0E9","filled":"","dense":"","flat":"","placeholder":"Ingresa tu documento de identidad","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.doc_number),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "doc_number", $$v)},expression:"getAuthUser.buyer.doc_number"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"telefono"}},[_vm._v(" Telefono ")]),_c('ValidationProvider',{attrs:{"name":"Numero de telefono","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"#00A0E9","filled":"","dense":"","flat":"","placeholder":"Ingresa tu numero de telefono","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.phone),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "phone", $$v)},expression:"getAuthUser.buyer.phone"}})]}}],null,true)})],1),_c('v-col',{staticClass:"mt-md-n5",attrs:{"cols":"12","md":"6"}},[_c('ValidationProvider',{attrs:{"name":"Correo","rules":"email|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"text-uppercase font-weight-bold",attrs:{"for":"email"}},[_vm._v(" Correo ")]),_c('v-text-field',{staticClass:"mt-2",attrs:{"color":"#00A0E9","filled":"","dense":"","flat":"","placeholder":"Ingresa tu correo electronico","error-messages":errors},model:{value:(_vm.getAuthUser.buyer.email),callback:function ($$v) {_vm.$set(_vm.getAuthUser.buyer, "email", $$v)},expression:"getAuthUser.buyer.email"}})]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex justify-center my-5"},[_c('v-btn',{staticClass:"text-capitalize btn-update",attrs:{"loading":_vm.loading,"rounded":"","elevation":"0","dark":"","color":"#00A0E9"},on:{"click":function($event){return passes(_vm.HandlerUpdateProfile)}}},[_vm._v(" Actualizar ")])],1)],1):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }